import React from 'react'
import {Link} from 'gatsby'

const ServicesOne = () => {
    return (
        <section className="solutions-area pt-100 pb-70">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-rocket"></i>
                            </div>
                            <h3>
                                <Link to="/service-detailsbd">
                                  Big Data
                                </Link>
                            </h3>
                            <p>Use our advanced analytics techniques to identify key patterns & insights in very large diverse data sets to drive business benefits.</p>
                            <Link className="view-details-btn" to="/service-detailsbd">
                                View Details
                            </Link>
                            
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-laptop"></i>
                            </div>
   
                            <h3>
                                <Link to="/service-detailsdv">
                                    Data Visualization
                                </Link>
                            </h3>

                            <p>Highlight key business information in an easy to consume story telling visual dashboards using today's market leading data visualization tools.</p>
                            <Link className="view-details-btn" to="/service-detailsdv">
                                View Details
                            </Link>
                            
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-money"></i>
                            </div>

                            <h3>
                                <Link to="/service-detailsdw">
                                    Data Warehousing
                                </Link>
                            </h3>

                            <p>Design & develop agile data warehouse using latest Cloud native & engineered appliances using our automation framework 2.0.</p>
                            <Link className="view-details-btn" to="/service-detailsdw">
                                View Details
                            </Link>
                            
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-segmentation"></i>
                            </div>

                            <h3>
                                <Link to="/service-detailsdm">
                                    Data Management
                                </Link>
                            </h3>

                            <p>Helping you organize your data cost effectively & efficiently using modern best practices & latest tools and technologies.</p>
                            <Link className="view-details-btn" to="/service-detailsdm">
                                View Details
                            </Link>
                            
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-analytics"></i>
                            </div>

                            <h3>
                                <Link to="/service-detailsbi">
                                    Business Intelligence
                                </Link>
                            </h3>

                            <p>Certified experts helping you create visually appealing business intelligence reports & dashboards in marketing leading BI tools of your choice.</p>
                            <Link className="view-details-btn" to="/service-detailsbi">
                                View Details
                            </Link>
                            
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-settings"></i>
                            </div>

                            <h3>
                                <Link to="/service-detailsmdm">
                                    Master Data Management
                                </Link>
                            </h3>

                            <p>Author, Deploy & safeguard master data management policies, principles, guidelines using our MDM framework & decades of experience.</p>
                            
                            <Link className="view-details-btn" to="/service-detailsmdm">
                                View Details
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServicesOne